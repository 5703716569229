<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <h1 style="margin:0,0,6000px,0;">1</h1> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

  <IndexComponent/>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import IndexComponent from './pages/index/IndexComponentcopy.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
    IndexComponent
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
 

  /* 背景  */
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: linear-gradient(45deg, #ff3366, #33ccff); /* 使用渐变色作为背景 */


}


</style>
