<template>
    <div id="app">
      <div class="scroll-text-container">
        <div class="carousel-content">
          <div class="scroll-text-wrapper" ref="scrollWrapper">
            <div
              class="scroll-content"
              :style="{ animationDuration: `${this.scrollSpeed}s` }"
              @transitionend="handleTransitionEnd"
            >
              <div v-for="(item, index) in apiData" :key="index" class="scroll-text-content">
                <!-- <div v-for="(item, index) in itemsWithDuplicate" :key="index" class="scroll-text-content" :style="{ backgroundColor: generateRandomColor() }"> -->
                  <div class="item-container">
                    <div class="text-lines-container">
                      <div class="text-lines">
                        <h1>朵达-青少年近视防控</h1>
                      </div>
                      <div class="text-lines">
                        <div class="line-item" style="font-size: 14px">
                          <span class="line-text">姓名: {{ item.name }} &nbsp;&nbsp;</span>
                          <span class="line-text">性别: {{ item.sex }} &nbsp;&nbsp;</span>
                          <span class="line-text">年龄: {{ item.age }} &nbsp;&nbsp;</span>
                          <span class="line-text">视训号: {{ item.numbers }} &nbsp;&nbsp;</span>
                        </div>
                        <div class="line-item">
                          <span class="line-text" style="font-size: 16px">视觉训练开始检查日期: {{(item.startimes)}} &nbsp;&nbsp;签约: {{item.signingtime}}</span>
                        </div>
                       
                        <div class="line-item">
                          <table class="custom-table">
                            <thead>
                              <tr>
                                <th>&nbsp;&nbsp; </th>
                                <th v-for="header in headers" :key="header">{{ header }}</th>
                                <!-- Add more headers as needed -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>右眼</td>
                                <td v-for="col in eyeDataColumns" :key="col">
                                  {{ item[`right1_${col}`] }}
                                </td>
                              </tr>
                              <tr>
                                <td>左眼</td>
                                <td v-for="col in eyeDataColumns" :key="col">
                                  {{ item[`left1_${col}`] }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="line-item">
                          <span class="line-text" style="font-size: 18px">最近一次复查日期: {{(item.reviewtime)}} </span>
                        </div>
                        <div class="line-item">
                          <table class="custom-table">
                            <thead>
                              <tr>
                                <th>&nbsp;&nbsp; </th>
                                <th v-for="header in headers" :key="header">{{ header }}</th>
                                <!-- Add more headers as needed -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>右眼</td>
                                <td v-for="col in eyeDataColumns" :key="col">
                                  {{ item[`right2_${col}`] }}
                                </td>
                              </tr>
                              <tr>
                                <td>左眼</td>
                                <td v-for="col in eyeDataColumns" :key="col">
                                  {{ item[`left2_${col}`] }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
  
                        <div class="line-item left-align">
                          <span class="line-text" >训练: {{item.traintime}}个月</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span class="line-text">训练：{{item.trainnumber}}次</span>
                        </div>
  
                        <div class="line-item left-align">
                          <span  class="line-text">  视觉训练效果: {{item.traineffect}}</span> 
                        </div>
  
  
  
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        headers: [
        '球镜', '柱镜', '轴向', '棱镜', '基底', '棱镜', '基底', '矫正视力',
        // Add more headers as needed
      ],
      columnsToRender: [
        'right1_2', 'right1_3', 'right1_4', 'right1_5', 'right1_6', 'right1_7', 'right1_8',
        'left1_1', 'left1_2', 'left1_3', 'left1_4', 'left1_5', 'left1_6', 'left1_7', 'left1_8',
        // Add more columns as needed
      ],
      scrollSpeed: 0,
        apiData: [],
        // items: [
        //   {
        //     name: "张三",
        //     sex: "男",
        //     age: "18",
        //     numbers: "0021547",
        //     // image: "https://via.placeholder.com/150x150",
        //   },
        //   {
        //     name: "李四",
        //     sex: "男",
        //     age: "18",
        //     numbers: "0254798",
        //     // image: "https://via.placeholder.com/150x150",
        //   },
        //   {
        //     name: "王五",
        //     sex: "男",
        //     age: "18",
        //     numbers: "0254798",
        //     // image: "https://via.placeholder.com/150x150",
        //   },
        //   {
        //     name: "赵六",
        //     sex: "男",
        //     age: "18",
        //     numbers: "0254798",
        //     // image: "https://via.placeholder.com/150x150",
        //   },
        //   {
        //     name: "田七",
        //     sex: "男",
        //     age: "18",
        //     numbers: "0254798",
        //     // image: "https://via.placeholder.com/150x150",
        //   },
         
        //   // Add more items as needed
        // ],
        eyeDataColumns: [1, 2, 3, 4, 5, 6, 7, 8],
        animationDuration: 0,
        currentIndex: 0,
         // 新增一个数据集
        // 新增一个数据集
        // mockData: {
        //   type1: [
        //     "数据1",
        //     "数据3",
        //     "数据5",
        //     "数据7",
        //     "数据9",
        //   ],
        //   type2: [
        //     "数据2",
        //     "数据4",
        //     "数据6",
        //     "数据8",
        //   ],
        // },
  
      };
    },
    computed: {
      itemsWithDuplicate() {
        // Add duplicates of the items for seamless scrolling
        return [...this.items];
      },
    },
    created() {
    // Make an API request when the component is created
      this.fetchData();
      // 每隔一段时间刷新数据（这里以60000毫秒，即1分钟为例）
      this.refreshInterval = setInterval(() => {
        this.fetchData();
      }, 60000);


    },

    beforeUnmount() {
      // 在组件销毁前清除定时器
      clearInterval(this.refreshInterval);
    },

    methods: {
      //接口获取数据
      fetchData() {
      // Assuming you have an API endpoint to fetch data
      const apiUrl = 'https://movie.timexz.com/api/getinfo';
      const speedApiUrl = 'https://movie.timexz.com/api/getspeed';
   //  const apiUrl = 'http://127.0.0.1:8000/';

      // Use your preferred method to make an HTTP request (e.g., fetch, axios)
      // Fetch example:
      //获取全部数据
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          // Update the component's data with the fetched API data
          this.apiData = data.data;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });

        // Fetch example for speed data
        //获取滚动的速度
      fetch(speedApiUrl)
        .then((response) => response.json())
        .then((data) => {
          // Update the component's data with the fetched scrolling speed
          this.scrollSpeed = data.data;
        })
        .catch((error) => {
          console.error('Error fetching speed data:', error);
        });

    },
    


      startScroll() {
        const content = this.$el.querySelector(".scroll-content");
        const totalWidth = content.offsetWidth;
  
        // Set up a timeout to ensure initial styles are applied before transition
        setTimeout(() => {
          // Ensure start position is at the end for seamless scrolling
          content.style.transition = "none";
          content.style.transform = `translateX(-${totalWidth}px)`;
  
          // Force reflow to apply the transform immediately
          void content.offsetWidth;
  
          // Set up a timeout to start the initial scroll
          setTimeout(() => {
            content.style.transition = `transform ${this.animationDuration}s linear`;
            content.style.transform = `translateX(0)`;
          }, 0);
  
          // Set up an event listener to detect the end of the first scroll
          content.addEventListener("transitionend", () => {
            // Reset transition and move back to the end for seamless scrolling
            content.style.transition = "none";
            content.style.transform = `translateX(-${totalWidth}px)`;
  
            // Force reflow to apply the transform immediately
            void content.offsetWidth;
  
            // Set up a timeout to reapply transition for subsequent scrolls
            setTimeout(() => {
              content.style.transition = `transform ${this.animationDuration}s linear`;
              content.style.transform = `translateX(0)`;
            }, 0);
          });
        }, 0);
      },
      

      // 格式化日期字符串
      // formatDateString(dateString) {
      //   const date = new Date(dateString);
      //   const options = { year: 'numeric', month: 'long', day: 'numeric' };
      //   const formatter = new Intl.DateTimeFormat('zh-CN', options);
      //   return formatter.format(date);
      // },
     

  
      // 生成模拟数据的方法
      generateRandomData() {
        const randomIndex = Math.floor(Math.random() * this.mockData.length);
        return this.mockData[randomIndex];
      },
  
      //新增一个接收类型参数
      generateRandomDataByType(type) {
        const data = this.mockData[type];
        const randomIndex = Math.floor(Math.random() * data.length);
        return data[randomIndex];
      },
  
      generateRandomColor() {
        // 生成随机的十六进制颜色值
        const randomColor = "#" + Math.floor(Math.random()*16777215).toString(16);
        return randomColor;
      },
      handleTransitionEnd() {
        const content = this.$el.querySelector(".scroll-content");
        const totalWidth = content.offsetWidth;
  
        // Update current index to keep track of the displayed items
        this.currentIndex++;
  
        // If reached the end of the first set, reset the index and scroll to the right
        if (this.currentIndex === this.items.length) {
          this.currentIndex = 0;
          content.style.transition = "none";
          content.style.transform = `translateX(-${totalWidth}px)`;
          void content.offsetWidth; // Force reflow
        }
  
        // Reapply transition and start scrolling
        content.style.transition = `transform ${this.animationDuration}s linear`;
        content.style.transform = `translateX(0)`;
      },
    },
  
    watch: {
      currentIndex(newIndex) {
        // Update items array based on the current index
        this.items = this.itemsWithDuplicate.slice(newIndex, newIndex + this.items.length);
      },
    },
    mounted() {
      this.startScroll();
    },
      

  };


  </script>
  
  
  <style>
  .scroll-text-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  
  .carousel-content {
    display: flex;
  }
  
  .scroll-text-wrapper {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .scroll-content {
    display: inline-block;
    white-space: nowrap;
    animation: scrollAnimation linear infinite;
  }
  
  .scroll-text-content {
    display: inline-block;
    color: #333;
    margin-right: 10px;
    color: white; /* 设置文本颜色为白色，以适应不同的背景颜色 */
    border-radius: 10px; /* 添加圆角 */
    background-color: transparent; /* 或者直接移除该行，去掉背景颜色 */
  }
  
  .item-container {
    border: 2px solid #ddd; /* Adjust border size as needed */
    border-radius: 10px; /* Adjust border radius as needed */
    margin: 10px;
  }
  
  .text-lines-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  
  .text-lines {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .line-item {
    margin-bottom: 5px;
  }
  
  .line-text {
    font-size: 1.5em; /* Adjust the font size as needed */
  }
  

  
  .scroll-text-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background: linear-gradient(45deg, #ff3366, #33ccff); /* 使用渐变色作为背景 */
  }
  
  
  /* 表格 */
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table td {
    border: 1px solid #ddd;
    padding: 5px;
    text-align: center;
  }
  
  /* 对其 */
  .left-align {
    text-align: left;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table td {
    border: 1px solid #ddd;
    padding: 5px;
    text-align: center;
  }
  
  /* 表格 */
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table td, .custom-table th {
    border: 1px solid #ddd; /* 添加表格和表头的边框样式 */
    padding: 8px; /* 根据需要添加内边距 */
    text-align: center;
  }
  
  /* 对其 */
  .left-align {
    text-align: left;
  }
  
  @keyframes scrollAnimation {
    to {
      transform: translateX(-100%);
    }
  }

  /* 通用样式 */
.scroll-text-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.carousel-content {
  display: flex;
}

.scroll-text-wrapper,
.scroll-content {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}

.scroll-content {
  animation: scrollAnimation linear infinite;
}

.scroll-text-content {
  display: inline-block;
  color: white;
  border-radius: 10px;
  background-color: transparent;
  margin-right: 10px;
}

.item-container {
  border: 2px solid #ddd;
  border-radius: 10px;
  margin: 10px;
}

.text-lines-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.text-lines {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line-item {
  margin-bottom: 5px;
}

.line-text {
  font-size: 1.5em;
}



/* 表格样式 */
.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table td,
.custom-table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

/* 左对齐样式 */
.left-align {
  text-align: left;
}

@keyframes scrollAnimation {
  to {
    transform: translateX(-100%);
  }
}
  </style>
  